<template>
	<div id="people">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>管理</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">角色权限</a></el-breadcrumb-item>
			</el-breadcrumb>

			<div class="content" style="height: 85vh;">
				<div class="title u-f-item u-f-jsb">
					<div>共{{ rolenum }}条数据</div>
					<div class="u-f-item">
						<el-input placeholder="请输入内容" v-model="sousuo1" size="small" style="margin-right: 10px;">
							<i slot="prefix" class="el-input__icon el-icon-search"></i>
						</el-input>
						<el-button size="small" type="primary" @click="search1()">搜索</el-button>
						<el-button size="small" @click="RoleShowTap(1)">添加角色</el-button>
						<!-- <el-button size="small" type="primary">导出</el-button>
							<el-button size="small" icon="el-icon-s-operation">排序</el-button> -->
					</div>
				</div>
				<el-table
					:data="roleList"
					height="85%"
					ref="multipleTable"
					tooltip-effect="dark"
					@selection-change="handleSelectionChange"
					style="width: 100%;border: 1px solid #EEEEEE;"
				>
					<el-table-column type="selection" width="55"></el-table-column>
					<el-table-column type="index" label="序号" width="150"></el-table-column>
					<el-table-column prop="name" label="角色名称" width="200"></el-table-column>
					<el-table-column prop="user" label="人员">
						<template slot-scope="scope">
							<el-tooltip class="item" effect="dark" :content="scope.row.user_name" placement="top-start" v-if="scope.row.user_name">
								<div class="u-line-1" style="width: 90%;">{{ scope.row.user_name }}</div>
							</el-tooltip>
							<div v-else>-</div>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="120">
						<template slot-scope="scope">
							<el-button @click="RoleShowTap(2, scope.row)" type="text" size="small">编辑</el-button>
							<span style="color: #E0E0E0;">|</span>
							<el-button @click="openNode(scope.row)" type="text" size="small">权限</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<el-dialog :title="role_title" :visible.sync="addRoleShow" width="600px" :before-close="CloseHandle">
				<!--选择 -->
				<div class="selectShow">
					<el-dialog custom-class="selectShow" :visible.sync="selectShow" width="800px" :before-close="handleClose" append-to-body>
						<div slot="title" class="u-f-item dialog-title">
							<div style="margin-right: 10px;">选择联系人</div>
						</div>
						<div class="user-list-box">
							<div class="u-f-item u-f-jsb user-list-head">
								<div class="user-type-list u-f-item">
									<div
										class="user-type-item u-f-justify"
										@click="typeTap(index)"
										:class="typeIndex == index ? 'active' : ''"
										v-for="(item, index) in teacher_list"
										:key="index"
									>
										{{ item.name }}
										<span v-if="index + 1 < teacher_list.length">|</span>
									</div>
								</div>

							</div>
							<div class="user-list-content u-f">
								<div class="u-f2 left" v-if="teacher_list.length">
									<div
										class="left-item"
										@click="dataTap(index)"
										:class="dataIndex == index ? 'active' : ''"
										v-for="(item, index) in teacher_list[typeIndex].data"
										:key="index"
									>
										{{ item.name }}
									</div>
								</div>
								<div class="u-f4 right" v-if="teacher_list.length">
									<div class="list" v-if="teacher_list[typeIndex].data[dataIndex]">
										<div
											class="item"
											@click="teachTap(item)"
											:class="item.checked ? 'active' : ''"
											v-for="(item, index) in teacher_list[typeIndex].data[dataIndex].teacher"
										>
											{{ item.username }}
										</div>
										<div class="">
											<el-button @click="QuanTap" type="primary" size="mini" v-if="!teacher_list[typeIndex].data[dataIndex] || !teacher_list[typeIndex].data[dataIndex].isQuan">{{typeIndex==0?'学科':'部门'}}全选</el-button>
											<el-button @click="QuanTap1" type="primary" size="mini" v-if="teacher_list[typeIndex].data[dataIndex] && teacher_list[typeIndex].data[dataIndex].isQuan">取消全选</el-button>
										</div>
									</div>

								</div>
							</div>
						</div>
						<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
							<div class="u-f-item">
								<el-button @click="reset" size="small">重置</el-button>
								<el-button @click="allTap" type="primary" size="small" v-if="!isBnt">全选</el-button>
								<el-button @click="allTap1" type="primary" size="small" v-if="isBnt">取消全选</el-button>
							</div>
							<div class="u-f-item">
								<span style="color: #A4A4A4;margin-right: 10px;">{{ teachers.length }}/{{ nums.length }}</span>
								<el-button type="primary" size="small" @click="selectShow = false">确 定</el-button>
							</div>
						</span>
					</el-dialog>
				</div>
				<div class="edit-box" style="padding:20px 0 20px 40px ;max-height: 500px;">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 50px;">
							<span>*</span>
							名称
						</div>
						<el-input v-model="role_name" placeholder="请输入名称" style="width: 75%;"></el-input>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal" style="width: 50px;padding-top: 10px;">人员</div>
						<div class="u-f" style="width: 85%;">
							<el-select v-model="teachers" multiple popper-class="el_option" style="width: 66%;" @remove-tag="removeTag">
								<el-option style="display: none;" v-for="(item, index) in selectList" :key="index" :label="item.username" :value="item.teacher_id"></el-option>
							</el-select>
							<el-button style="margin-left: 10px;height: 40px;" @click="selectShow = true">选择</el-button>
						</div>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="closeRoleShow" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submitAddRole">确 定</el-button>
				</span>
			</el-dialog>

			<!-- 权限 -->
			<el-dialog title="权限配置" :visible.sync="NodeVisible" width="500px" :before-close="CloseNodeVisible">
				<div class="edit-box" style="padding:20px 40px;">
					<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
					<el-tree
						ref="tree"
						:data="nodeList"
						show-checkbox
						node-key="id"
						:expand-on-click-node="false"
            :check-strictly="isTreeStrictly"
            @check-change="TreeChange"
					>
						<span class="custom-tree-node" slot-scope="{ node, data }">
							<span>{{ data.title }}</span>
						</span>
					</el-tree>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="CloseNodeVisible" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="setGroupRule">确 定</el-button>
				</span>
			</el-dialog>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			isQuan:false,
			type: 1,
			limit: 10,
			total: 0,
			userid: '',
			ids: [],
			name: '',
			birthday: '',
			page: 1,
			sousuo: '',
			sousuo1: '',
			addVisible: false,
			VisibleTitle: '添加人员',
			powerVisible: false,
			sex: 1,
			is_discipline: 1,
			is_department: 1,
			identity_type: 1,
			staff_type: 1,
			phone: '',
			identity: '',
			email: '',
			QQ: '',
			group_id: '',
			school_discipline_id: '',
			school_department_id: '',
			subjectList: [],
			sectionList: [],
			Meun: [{ name: '教师管理' }, { name: '角色权限' }], //, { name: '角色权限' },
			MeunIndex: 0,
			condition: '',
			conditionList: [],
			peopleList: [],
			checkAll: false,
			isIndeterminate: false,
			nodeList: [],
			roleList: [],
			rolenum: 0,
			addRoleShow: false,
			teacher_list: [],
			key: '',
			typeIndex: 0,
			dataIndex: 0,
			checkboxGroup1: [],
			teachers: [],
			nums: [],
			selectShow: false,
			selectList: [],
			role_name: '',
			role_title: '添加角色',
			role_id: '',
			role_type: 1,
			NodeVisible: false,
			defaultChecked: [],
			nodeChecked: [],
			isBnt:false,
			nodeCheckedList:[],
      isTreeStrictly: true,
		};
	},
	mounted() {
		this.userList();
		this.getsubjectList();
		this.getsectionList();
		this.teacherList();
		this.getroleList();
	},
	methods: {
		// 获取权限节点列表
		ruleList() {
			return this.$api.pepople.ruleList().then(res => {
				if (res.data.code == 1) {
					this.nodeList = res.data.data.list;
					this.nodeChecked = res.data.data.ids;
					console.log(this.nodeChecked);
					this.checkAll = this.defaultChecked.length === this.nodeChecked.length;
					this.isIndeterminate = this.defaultChecked.length > 0 && this.defaultChecked.length < this.nodeChecked.length;
				}
			});
		},
		// 用户权限
		openPower(row) {
			this.nodeList = [];
			if (row.rules.length) {
				this.defaultChecked = row.rules;
				this.nodeCheckedList =  row.rules;
			} else {
				this.defaultChecked = [];
				this.nodeCheckedList = [];
			}
			this.ruleList();
			this.powerVisible = true;
		},
		// 角色权限
		async openNode(row) {
			this.nodeList = [];
      this.isTreeStrictly = true
			if (row.rules.length) {
				this.defaultChecked = row.rules;
				this.nodeCheckedList =  row.rules;
			} else {
				this.defaultChecked = [];
				this.nodeCheckedList =  [];
			}
			this.role_id = row.id;
      this.NodeVisible = true;
			await this.ruleList();
      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys(row.rules)
        this.isTreeStrictly = false
      })
		},
		// 角色权限设置
		setGroupRule() {
			this.$api.pepople
				.setGroupRule({
					id: this.role_id,
					rules: this.nodeCheckedList.toString()
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('设置成功');
						this.getroleList();
						this.NodeVisible = false;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		CloseNodeVisible() {
			this.defaultChecked = [];
			this.NodeVisible = false;
		},
		RoleShowTap(type, row) {
			this.role_type = type;
			if (type == 1) {
				this.role_title = '添加人员';
			} else {
				this.role_title = '编辑人员';
				this.role_name = row.name;
				this.teachers = row.user_id;
				this.role_id = row.id;
				for (let i in this.teacher_list) {
					for (let j in this.teacher_list[i].data) {
						for (let t in this.teacher_list[i].data[j].teacher) {
							for (let s in this.teachers) {
								if (this.teacher_list[i].data[j].teacher[t].teacher_id == this.teachers[s]) {
									this.teacher_list[i].data[j].teacher[t].checked = true;
								}
							}
						}
					}
				}
			}
			this.addRoleShow = true;
		},
		// 重置
		reset() {
			this.teachers = [];
			this.teacherList();
		},
		allTap(){
			let arr = [];
			for (let i in this.teacher_list) {
				for (let j in this.teacher_list[i].data) {
					for (let t in this.teacher_list[i].data[j].teacher) {
						this.teacher_list[i].data[j].teacher[t].checked = true;
						arr.push(this.teacher_list[i].data[j].teacher[t].teacher_id);
					}
				}
			}
			this.teachers = arr;
			this.teachers = this.unique(this.teachers);
			this.isBnt = true;
		},
		allTap1(){
			this.reset();
			this.isBnt = false;
		},
		QuanTap(){
			let arr = [];
			for (let t in this.teacher_list[this.typeIndex].data[this.dataIndex].teacher) {
				this.teacher_list[this.typeIndex].data[this.dataIndex].teacher[t].checked = true;
				arr.push(this.teacher_list[this.typeIndex].data[this.dataIndex].teacher[t].teacher_id);
			}
			this.teachers = this.teachers.concat(arr);
			this.teachers = this.unique(this.teachers);
			this.teacher_list[this.typeIndex].data[this.dataIndex].isQuan = true;
		},
		QuanTap1(){
			let arr = [];
			for (let t in this.teacher_list[this.typeIndex].data[this.dataIndex].teacher) {
				this.teacher_list[this.typeIndex].data[this.dataIndex].teacher[t].checked = false;
			}
			for (let i in this.teacher_list) {
				for (let j in this.teacher_list[i].data) {
					for (let t in this.teacher_list[i].data[j].teacher) {
						if(this.teacher_list[i].data[j].teacher[t].checked){
							arr.push(this.teacher_list[i].data[j].teacher[t].teacher_id);
						}
					}
				}
			}
			this.teachers = arr;
			this.teachers = this.unique(this.teachers);
			this.teacher_list[this.typeIndex].data[this.dataIndex].isQuan = false;
		},
		closeRoleShow() {
			this.role_id = '';
			this.role_name = '';
			this.teachers = [];
			this.teacherList();
			this.addRoleShow = false;
		},
		// 搜索教师
		search() {
			this.userList();
		},
		// 搜索角色
		search1() {
			this.getroleList();
		},
		// 切换分类
		typeTap(index) {
			this.typeIndex = index;
			this.dataIndex = 0;
		},
		dataTap(index) {
			this.dataIndex = index;
		},
		// 添加/编辑角色
		submitAddRole() {
			if (this.role_name == '' || !this.role_name) {
				return this.$message.error('请输入名称');
			}
			if (this.role_type == 1) {
				this.$api.pepople
					.addGroup({
						name: this.role_name,
						user_id: this.teachers
					})
					.then(res => {
						if (res.data.code == 1) {
							this.$message.success('添加成功');
							this.getroleList();
							this.addRoleShow = false;
							this.role_name = '';
							this.teachers = [];
							this.teacherList();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			} else if (this.role_type == 2) {
				this.$api.pepople
					.editGroup({
						name: this.role_name,
						user_id: this.teachers,
						id: this.role_id
					})
					.then(res => {
						if (res.data.code == 1) {
							this.$message.success('编辑成功');
							this.getroleList();
							this.addRoleShow = false;
							this.role_name = '';
							this.teachers = [];
							this.teacherList();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			}
		},
		// 选择老师
		teachTap(item) {
			let arr = [];
			for (let i in this.teacher_list) {
				for (let j in this.teacher_list[i].data) {
					for (let t in this.teacher_list[i].data[j].teacher) {
						if (this.teacher_list[i].data[j].teacher[t].teacher_id == item.teacher_id && !this.teacher_list[i].data[j].teacher[t].checked) {
							this.teacher_list[i].data[j].teacher[t].checked = true;
						} else if (this.teacher_list[i].data[j].teacher[t].teacher_id == item.teacher_id && this.teacher_list[i].data[j].teacher[t].checked) {
							this.teacher_list[i].data[j].teacher[t].checked = false;
						}
						if (this.teacher_list[i].data[j].teacher[t].checked) {
							arr.push(this.teacher_list[i].data[j].teacher[t].teacher_id);
						}
					}
				}
			}
			this.teachers = arr;
			this.teachers = this.unique(this.teachers);
		},
		removeTag() {
			for (let i in this.teacher_list) {
				for (let j in this.teacher_list[i].data) {
					for (let t in this.teacher_list[i].data[j].teacher) {
						if (this.teachers.length) {
							for (let c in this.teachers) {
								if (this.teacher_list[i].data[j].teacher[t].teacher_id == this.teachers[c]) {
									this.teacher_list[i].data[j].teacher[t].checked = true;
								} else {
									this.teacher_list[i].data[j].teacher[t].checked = false;
								}
							}
						} else {
							this.teacher_list[i].data[j].teacher[t].checked = false;
						}
					}
				}
			}
		},
		unique(arr) {
			// 根据唯一标识orderId来对数组进行过滤
			const res = new Map(); //定义常量 res,值为一个Map对象实例 //返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
			return arr.filter(arr => !res.has(arr) && res.set(arr, 1));
		},
		unique2(arr) {
			// 根据唯一标识orderId来对数组进行过滤
			const res = new Map(); //定义常量 res,值为一个Map对象实例 //返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
			return arr.filter(arr => !res.has(arr.teacher_id) && res.set(arr.teacher_id, 1));
		},
		twacherTap(index) {
			this.MeunIndex = index;
		},
		// 权限全选
		handleCheckAllChange(val) {
			this.defaultChecked = val ? this.nodeChecked : [];
			this.$refs.tree.setCheckedKeys(this.defaultChecked);
			this.isIndeterminate = false;
		},
		// 权限选择
		TreeChange(data, checked, indeterminate) {
			let list = this.$refs.tree.getCheckedNodes(false,true);
			let arr = [];
			for (let i in list) {
				arr.push(list[i].id);
			}
			this.nodeCheckedList = arr;
			console.log(arr)
			this.checkAll = this.nodeCheckedList.length === this.nodeChecked.length;
			this.isIndeterminate = this.nodeCheckedList.length > 0 && this.nodeCheckedList.length < this.nodeChecked.length;
		},
		handleSelectionChange(val) {
			let arr = [];
			for (let i in val) {
				arr.push(val[i].id);
			}
			this.ids = arr;
			console.log(this.ids);
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.userList();
		},
		handleClose(done) {
			this.initialize();
			done();
		},
		CloseHandle(done) {
			this.role_name = '';
			this.teachers = [];
			this.teacherList();
			done();
		},
		// 弹窗
		VisibleTap(type, item) {
			this.type = type;
			if (type == 1) {
				this.VisibleTitle = '添加人员';
			} else if (type == 2) {
				console.log(item);
				this.VisibleTitle = '编辑人员';
				this.userid = item.id;
				this.name = item.username;
				this.sex = Number(item.gender);
				this.phone = item.mobile;
				this.group_id = item.group_id;
				this.identity = item.identity_code;
				this.birthday = item.birthday;
				this.school_discipline_id = item.school_discipline_id;
				this.school_department_id = item.school_department_id;
				this.is_discipline = Number(item.is_discipline);
				this.is_department = Number(item.is_department);
				this.identity_type = Number(item.identity_type);
				this.staff_type = Number(item.staff_type);
				this.QQ = item.qq;
				this.email = item.email;
			}
			this.addVisible = true;
		},
		// 关闭弹窗
		closeVisible() {
			this.initialize();
			this.addVisible = false;
		},
		// 添加、编辑人员
		submit() {
			if (this.type == 1) {
				this.addUser();
			} else if (this.type == 2) {
				this.editUser();
			}
		},
		// 删除人员
		delUser() {
			if (this.ids.length) {
				this.$confirm('确定要删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				}).then(() => {
					this.$api.setting
						.delUser({
							id: this.ids
						})
						.then(res => {
							if (res.data.code == 1) {
								this.$message.success('删除成功');
								this.userList();
							} else {
								this.$message.error(res.data.msg);
							}
						});
				});
			} else {
				this.$message.error('请选择要删除的人员');
			}
		},
		// 学科信息
		getsubjectList() {
			this.$api.setting.subjectList({}).then(res => {
				if (res.data.code == 1) {
					let list = res.data.data;
					this.subjectList = list;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		// 部门列表
		getsectionList() {
			this.$api.setting.sectionList({}).then(res => {
				if (res.data.code == 1) {
					let list = res.data.data.rows;
					this.sectionList = list;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		// 教师列表
		teacherList() {
			this.$api.pepople.teacherList({}).then(res => {
				if (res.data.code == 1) {
					this.teacher_list = res.data.data;
					for (let i in this.teacher_list) {
						if(this.teacher_list[i].data.length){
							for (let j in this.teacher_list[i].data) {
								for (let t in this.teacher_list[i].data[j].teacher) {
									this.nums = this.nums.concat(this.teacher_list[i].data[j].teacher[t].teacher_id);
									this.selectList = this.selectList.concat(this.teacher_list[i].data[j].teacher[t]);
								}
							}
						}
					}
					this.nums = this.unique(this.nums);
					this.selectList = this.unique2(this.selectList);
				}
			});
		},
		// 人员列表
		userList() {
			let data = {};
			if (this.sousuo) {
				data.username = this.sousuo;
			}
			this.$api.setting
				.userList({
					limit: this.limit,
					page: this.page,
					filter: JSON.stringify(data),
					op: { username: 'like' }
				})
				.then(res => {
					if (res.data.code == 1) {
						this.peopleList = res.data.data.rows;
						this.total = res.data.data.total;
					}
				});
		},
		// 添加人员
		addUser() {
			if (this.name == '' || !this.name) {
				return this.$message.error('请输入姓名');
			}
			if (this.phone == '' || !this.phone) {
				return this.$message.error('请输入电话');
			}
			let data = {
				username: this.name,
				gender: this.sex,
				mobile: this.phone,
				group_id: this.group_id, //是 	number 	角色组id
				identity_code: this.identity,
				birthday: this.birthday, // 	否 	date 	生日
				school_discipline_id: this.school_discipline_id, // 	否 	number 	学科ID
				school_department_id: this.school_department_id, // 	否 	number 	部门ID
				is_discipline: this.is_discipline, // 	否 	number 	是否科组负责人:1=是,2=否
				is_department: this.is_department, // 	否 	number 	是否部门负责人:1=是,2=否
				identity_type: this.identity_type, // 	否 	number 	身份类别:1=教师,2=职员
				staff_type: this.staff_type, // 	否 	number 	职工类别:1=正编人员,2=非正编人员
				qq: this.QQ, // 	否 	number
				email: this.email
				// campus_id:this.condition
			};
			this.$api.setting.addUser(data).then(res => {
				if (res.data.code == 1) {
					this.$message.success('添加成功');
					this.initialize();
					this.userList();
					this.addVisible = false;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		// 编辑人员
		editUser() {
			if (this.name == '' || !this.name) {
				return this.$message.error('请输入姓名');
			}
			if (this.phone == '' || !this.phone) {
				return this.$message.error('请输入电话');
			}
			let data = {
				id: this.userid,
				username: this.name,
				gender: this.sex,
				mobile: this.phone,
				group_id: this.group_id, //是 	number 	角色组id
				identity_code: this.identity,
				birthday: this.birthday, // 	否 	date 	生日
				school_discipline_id: this.school_discipline_id, // 	否 	number 	学科ID
				school_department_id: this.school_department_id, // 	否 	number 	部门ID
				is_discipline: this.is_discipline, // 	否 	number 	是否科组负责人:1=是,2=否
				is_department: this.is_department, // 	否 	number 	是否部门负责人:1=是,2=否
				identity_type: this.identity_type, // 	否 	number 	身份类别:1=教师,2=职员
				staff_type: this.staff_type, // 	否 	number 	职工类别:1=正编人员,2=非正编人员
				qq: this.QQ, // 	否 	number
				email: this.email
				// campus_id:this.condition
			};
			this.$api.setting.editUser(data).then(res => {
				if (res.data.code == 1) {
					this.$message.success('添加成功');
					this.initialize();
					this.userList();
					this.addVisible = false;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		// 初始化表单
		initialize() {
			this.name = '';
			this.sex = 1;
			this.phone = '';
			this.group_id = ''; //是 	number 	角色组id
			this.identity = '';
			this.birthday = ''; // 	否 	date 	生日
			this.school_discipline_id = ''; // 	否 	number 	学科ID
			this.school_department_id = ''; // 	否 	number 	部门ID
			this.is_discipline = 1; // 	否 	number 	是否科组负责人:1=是,2=否
			this.is_department = 1; // 	否 	number 	是否部门负责人:1=是,2=否
			this.identity_type = 1; // 	否 	number 	身份类别:1=教师,2=职员
			this.staff_type = 1; // 	否 	number 	职工类别:1=正编人员,2=非正编人员
			this.QQ = ''; // 	否 	number
			this.email = '';
		},
		// 获取角色列表
		getroleList() {
			let data = {
				op: { name: 'like' },
				filter: {}
			};
			if (this.sousuo1) {
				data.filter.name = this.sousuo1;
			}
			this.$api.pepople.roleList(data).then(res => {
				if (res.data.code == 1) {
					let list = res.data.data.rows;
					this.roleList = list;
					this.rolenum = res.data.data.total;
				}
			});
		}
	}
};
</script>

<style lang="scss">
.el_option {
	display: none !important;
}
#people {
	.nav-tab-bar-box {
		background-color: #ffffff;
		padding: 0.52vw;
		margin-top: 1.04vw;
		.nav-tab-bar {
			.nav-tab-item {
				color: #6e7a87;
				padding: 0 0.78125vw;
				border-right: 1px solid #f3f3f3;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			&div:last-child {
				border-right: none;
			}
			.active {
				font-size: 0.833vw;
				color: #63acff;
				position: relative;
				font-weight: bold;
			}
			.active::after {
				position: absolute;
				content: '';
				height: 2px;
				left: 0.78125vw;
				right: 0.78125vw;
				background-color: #63acff;
				bottom: -0.53125vw;
			}
		}
	}
	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;
		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}

	.content {
		background-color: #ffffff;
		height: 79vh;
		margin-top: 20px;
		padding: 0 30px;
		.title {
			padding: 15px 0;
			.btn {
				background-color: #f3f3f3;
				font-size: 12px;
				padding: 5px 10px;
				color: #555555;
			}
		}
	}

	.el-table th > .cell,
	.el-table .cell {
		overflow: hidden; /*超出部分隐藏*/
		white-space: nowrap; /*不换行*/
		text-overflow: ellipsis; /*超出部分文字以...显示*/
	}
	.el-table thead {
		background: #fafafa !important;
	}
	.el-table th {
		padding: 15px 5px !important;
		background: #fafafa !important;
	}
	.el-table::before {
		left: 0;
		bottom: 0;
		width: 100%;
		height: 0;
	}
	.pad {
		background-color: #e4f1ff;
		color: #2d8cf0;
		padding: 6px 20px;
		display: inline-block;
	}
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0;
		.edit-box {
			padding: 0 20px 0 20px;
			max-height: 60vh;
			overflow-y: auto;
			.input-box {
				margin-top: 35px;
				.input-lebal {
					width: 25%;
					text-align: right;
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
				.el-select .el-input.is-disabled .el-input__inner {
					cursor: default;
				}
				.el-input.is-disabled .el-input__inner {
					background-color: #ffffff;
					cursor: default;
				}
				.el-input__prefix,
				.el-input__suffix {
					display: none;
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.dialog-title {
		padding: 10px 0 5px 0;
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
.selectShow {
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0;
		.user-list-box {
			.user-list-head {
				padding: 10px 20px;
				border-bottom: 1px solid #eeeeee;
				.user-type-list {
					.user-type-item {
						color: #8d9da6;
						cursor: pointer;
					}
					.active {
						color: #007aff;
						position: relative;
					}
					.active::after {
						position: absolute;
						width: 25px;
						height: 2px;
						background-color: #007aff;
						content: '';
						bottom: -5px;
						left: 0;
					}
					span {
						color: #cccccc;
						margin: 0 8px;
					}
				}
			}
			.user-list-content {
				height: 40vh;
				.left {
					height: 98%;
					overflow-y: auto;
					border-right: 1px solid #eeeeee;
					padding-top: 5px;
					.left-item {
						padding: 0 20px;
						height: 40px;
						line-height: 40px;
						color: #a4a4a4;
						cursor: pointer;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					.active {
						background-color: #e4f1ff;
						color: #2d8cf0;
						position: relative;
					}
					.active:after {
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						width: 2px;
						content: '';
						background-color: #2d8cf0;
					}
				}
				.right {
					height: 37vh;
					padding: 10px 25px;
					overflow-y: auto;
					.list {
						display: flex;
						flex-wrap: wrap;
						.item {
							cursor: pointer;
							padding: 5px 15px;
							border-radius: 3px;
							background-color: #f3f3f3;
							margin-right: 10px;
							margin-bottom: 10px;
						}
						.active {
							background-color: #e4f1ff;
							color: #2d8cf0;
						}
					}
				}
			}
		}
	}
	.dialog-title {
		padding: 10px 0 5px 0;
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
</style>
